export default function Favicons () {
  return (<>
    <link type="image/png" sizes="16x16" rel="icon" href="/favicon/icons8-naruto-sign-cloud-16.png" />
    <link type="image/png" sizes="32x32" rel="icon" href="/favicon/icons8-naruto-sign-cloud-32.png" />
    <link type="image/png" sizes="96x96" rel="icon" href="/favicon/icons8-naruto-sign-cloud-96.png" />
    <link color="#26E07F" rel="mask-icon" href="/favicon/icons8-naruto-sign-cloud-100.svg" />
    <link type="image/png" sizes="120x120" rel="icon" href="/favicon/icons8-naruto-sign-cloud-120.png" />
    <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/favicon/icons8-naruto-sign-cloud-180.png" />
    <link rel="icon" type="image/png" sizes="192x192" href="/favicon/icons8-naruto-sign-cloud-192.png" />
  </>);
}