import Stack from "@mui/material/Stack";
import GoogleTags from "./GoogleTags";
import YandexTags from "./YandexTags";
import Telegram from "./telegram";

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

export default function Footer () {
  return <>
    <Telegram />
    <Stack direction='row' gap='20px' margin='20px 0px'>
      <a href='/agreement' className='link'>Пользовательское соглашение <OpenInNewRoundedIcon style={{ fontSize: '11px' }} /></a>
      <a href='/dmca' className='link'>DMCA <OpenInNewRoundedIcon style={{ fontSize: '11px' }} /></a>
    </Stack>
    <p style={{ fontSize: '10pt' }}>Скачать мангу, манхву и другие комиксы можно бесплатно!</p>
    <p style={{ fontSize: '10pt' }}>Разработано специально для электронных книг в формате .CBR (Comic Book RAR) / PocketBook, Kindle и другие</p>
    <GoogleTags />
    <YandexTags />
  </>
}