import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TelegramIcon from '@mui/icons-material/Telegram'

import styles from './telegram.module.css';

const link = 'https://t.me/rumanganews';

export default function Telegram () {
    return (<Box className={styles.block} component={'a'} target='_blank' href={link}>
        <Stack
            component={'span'}
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={3}
        >
            <TelegramIcon style={{
                width: '100px',
                height: '100px'
            }} />
            <Stack
                component={'span'}
                direction="column"
                justifyContent="center"
                alignItems="start"
                style={{ flexGrow: 1 }}
            >
                <span>Сдедите за новинками и новостями в нашем телеграме!</span>
                <span><u>{link}</u></span>
            </Stack>
            <img className={styles.qr} src='/telegram-qr.svg' alt='RuManga: следи за новостями в нашем телеграм канале!' />
        </Stack>
    </Box>)
}