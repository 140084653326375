import * as cheerio from 'cheerio';

const collectionLabels: { [str: string]: { [n: string]: string } } = {
    'глава': {
        '0': 'глав',
        '1': 'глава',
        '2': 'главы',
        '3': 'главы',
        '4': 'главы',
        '5': 'глав',
        '6': 'глав',
        '7': 'глав',
        '8': 'глав',
        '9': 'глав'
    },
    'тайтл': {
        '0': 'тайтлов',
        '1': 'тайтл',
        '2': 'тайтла',
        '3': 'тайтла',
        '4': 'тайтла',
        '5': 'тайтлов',
        '6': 'тайтлов',
        '7': 'тайтлов',
        '8': 'тайтлов',
        '9': 'тайтлов'
    },
    'том': {
        '0': 'томов',
        '1': 'том',
        '2': 'тома',
        '3': 'тома',
        '4': 'тома',
        '5': 'томов',
        '6': 'томов',
        '7': 'томов',
        '8': 'томов',
        '9': 'томов'
    }
}

export function CorrectSpellLabel (n: number, str: 'глава' | 'тайтл' | 'том') {
    if (n > 10 && n < 20) {
        if (str === 'глава') return 'глав';
        if (str === 'тайтл') return 'тайтлов';
        if (str === 'том') return 'томов';
    }
    const lastN = n.toString().slice(-1);
    return collectionLabels[str][lastN];
}

export function CorrectSpellLabel2 (n: number, word: string, ending1: string, ending2: string, ending3: string) {
    if (n > 10 && n < 20) {
        return word + ending3;
    }
    const lastN = parseInt(n.toString().slice(-1));
    if (lastN == 1) {
        return word + ending1;
    }
    if (lastN > 1 && lastN < 5) {
        return word + ending2;
    }
    return word + ending3;
}

export function StripHtmlTags (html: string){
    const $ = cheerio.load(html);
    return $.text();
    // const doc = new DOMParser().parseFromString(html, 'text/html');
    // return doc.body.textContent || "";
}

export function shuffleArray<T> (array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}