import Script from "next/script";

export default function GoogleTags () {
  return (<>
    <Script async src="https://www.googletagmanager.com/gtag/js?id=G-Q3TY0LV1R2" />
    <Script id="google-analytics" defer>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){ dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-Q3TY0LV1R2');
      `}
    </Script>
    <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5395572218005783" crossOrigin="anonymous" />
  </>)
}