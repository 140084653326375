import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

import styles from './page.module.css';

export default function WalletItem (props: { img: string, address: string, network: string }) {
    return <Grid xs={6}>
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Box>
                <img
                    src={props.img}
                    style={{
                        width: '100px'
                    }}
                />
            </Box>
            <Box>
                <Typography variant="h6">
                    {props.network}
                </Typography>
                <Typography variant="caption" style={{ overflowWrap: 'anywhere' }}>
                    {props.address}
                </Typography>
            </Box>
        </Stack>
    </Grid>;
}

WalletItem.Stack = function WalletStack (props: { children: any }) {
    return (<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {props.children}
    </Grid>);
    return (<Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
        style={{ width: 'max-content' }}
    >{props.children}</Stack>)
}