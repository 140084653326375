import Head from 'next/head'
import dynamic from 'next/dynamic';
import { NextRequest } from 'next/server';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
// import HomeIcon from '@mui/icons-material/Home';
import Favorite from '@mui/icons-material/Favorite';

import styles from './index.module.css'
import Typography from '@mui/material/Typography';

import BookItem from '@/components/bookItem';
import WalletItem from '@/components/supportUs';

import GoogleTags from '@/components/GoogleTags';
import Favicons from '@/components/favicons';
import Telegram from '@/components/telegram';
import prisma from '@/components/server_side/prisma';
import { Prisma } from '@prisma/client';
import PostersBg from '@/components/PostersBg';
import YandexTags from '@/components/YandexTags';
import Footer from '@/components/footer';

const Top = dynamic(
	() => import('@/components/client_side/top'),
	{ ssr: false }
);

/**
 * Базовые подборки:
 * Популярное
 * Новые главы
 * Свежак
 */

type Item = {
  id: string
  name: string
  url: string
  type: string
  avgRating: string
}

type PageProps = {
  popular: Item[]
  updated: Item[]
  newest: Item[]
}

export async function getServerSideProps(req: NextRequest & { query: { [key: string]: string | string[] } }): Promise<{ props: PageProps }> {

  const ids = new Set<bigint>();

  const select: Prisma.mangaSelect = {
    id: true,
    url: true,
    name: true,
    type: true,
    avgRating: true
  };

  const popular = await prisma.manga.findMany({
    where: {
      avgRating: {
        gte: '8.0'
      },
      countRating: {
        gt: 30
      },
      issueYear: {
        not: null
      },
      updatedAt: {
        gte: new Date(new Date().setDate(-5))
      }
    },
    orderBy: [
      { issueYear: 'desc' },
      { avgRating: 'desc' }
    ],
    take: 20,
    select
  });

  popular.forEach(({ id }) => ids.add(id));

  const updated = await prisma.chapters.findMany({
    orderBy: [
      { publishedAt: 'desc' }
    ],
    take: 40,
    select: {
      branch: {
        select: {
          manga: {
            select
          }
        }
      }
    }
  })

  const newest = await prisma.chapters.findMany({
    where: {
      chapter: {
        lte: 3
      }
    },
    orderBy: [
      { publishedAt: 'desc' }
    ],
    take: 40,
    select: {
      branch: {
        select: {
          manga: {
            select
          }
        }
      }
    }
  })

  return {
    props: {
      popular: JSON.parse(JSON.stringify(popular)),
      updated: JSON.parse(JSON.stringify(
        updated.filter(_ => {
          const id = _.branch.manga.id;
          if (ids.has(id)) {
            return false;
          } else {
            ids.add(id);
            return true;
          }
        })
        .map(_ => _.branch.manga)
        .sort((a, b) => parseFloat(b.avgRating) - parseFloat(a.avgRating))
      )),
      newest: JSON.parse(JSON.stringify(
        newest.filter(_ => {
          const id = _.branch.manga.id;
          if (ids.has(id)) {
            return false;
          } else {
            ids.add(id);
            return true;
          }
        })
        .map(_ => _.branch.manga)
        .sort((a, b) => parseFloat(b.avgRating) - parseFloat(a.avgRating))
      ))
    }
  }
}

export default function Home(props: PageProps) {
  return (<>
    <Head>
      <title>{'Качаем мангу на RuManga'}</title>
      <meta name="description" content="Качай бесплатно мангу, манхуа и другие комиксы на нашем сайте! Формат CBR отлично подходит для электронной книги!" />
      <meta property="og:description" content="Качай бесплатно мангу, манхуа и другие комиксы на нашем сайте! Формат CBR отлично подходит для электронной книги!" />
      <meta property="og:image" content='/favicon/icons8-naruto-sign-cloud-192.png' />
      <meta property="og:site_name" content='Качаем мангу на RuManga' />
      {Favicons()}
    </Head>
    <main className={styles.main}>
      <Top searchOff={true} />
      <Typography variant="h6" display="block" gutterBottom style={{ color: 'orangered', width: '100%' }}>
        <Favorite sx={{ mr: 0.5 }} fontSize="inherit" /> Качай мангу и комиксы на русском языке
      </Typography>
      <Box
        style={{ width: '100%', marginTop: '30px', marginBottom: '50px' }}
        component="form"
        noValidate
        autoComplete="off"
        action={'/search'}
      >
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={3}
        >
          <TextField
            id="search_input"
            sx={{ m: 1, width: '100%', maxWidth: '70ch' }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              endAdornment: <Button variant="contained" color='inherit' type='submit' style={{ margin: '10px 0px' }}>Найти</Button>
            }}
            name='a'
            size='small'
          />
        </Stack>
      </Box>
      {
        props.updated.length ?
          <BookItem.Box title={'Новые главы'}>
            {
              props.updated.map(item => {
                return <BookItem
                  title={item.name}
                  img={`/api/manga/${item.url}/cover/medium.jpeg`}
                  type={item.type}
                  rating={item.avgRating}
                  url={'/manga/' + item.url}
                />
              })
            }
          </BookItem.Box>
        : ''
      }
      {
        props.popular.length ?
          <BookItem.Box title='Популярные тайтлы'>
            {
              props.popular.map(item => {
                return <BookItem
                  title={item.name}
                  img={`/api/manga/${item.url}/cover/medium.jpeg`}
                  type={item.type}
                  rating={item.avgRating}
                  url={'/manga/' + item.url}
                />
              })
            }
          </BookItem.Box>
        : ''
      }

      <Box style={{ marginBottom: '15px' }}>
        <Typography variant="h6" display="block" gutterBottom style={{ color: 'orangered' }}>
          Поддержка проекта
        </Typography>
        <WalletItem.Stack>
          <WalletItem
            img={'/wallets/bitcoin.png'}
            address={'1H3YYinYHKPRo4rWJKQR9Zk8qPtkQLN53K'}
            network={'Bitcoin'}
          />
          <WalletItem
            img={'/wallets/ethereum.png'}
            address={'0x94c5ce0524a0347912f3449b0f600221f914aa97'}
            network={'Ethereum'}
          />
        </WalletItem.Stack>
      </Box>

      {
        props.newest.length ?
          <BookItem.Box title='Открой для себя что-то новенькое!'>
            {
              props.newest.map(item => {
                return <BookItem
                  title={item.name}
                  img={`/api/manga/${item.url}/cover/medium.jpeg`}
                  type={item.type}
                  rating={item.avgRating}
                  url={'/manga/' + item.url}
                />
              })
            }
          </BookItem.Box>
        : ''
      }

      <Footer />
    </main>
    <PostersBg />
  </>)
}